import { reactive, ref, toRefs } from 'vue'
import axios from 'axios'
import axiosIns from '@axios'

const $http = axiosIns

const GMAP_KEY = 'AIzaSyAu-SLBocsCip7PD-bUZiWo6WD23wRr098'

export const $getLocations = async (params: object) => {
  const data = ref([])
  const count = ref(0)
  const isNextPage = ref(false)

  const state = reactive({
    error: null,
    loading: false,
  })

  try {
    state.loading = true

    const res = await $http.get('locations/', { params })

    data.value = res.data.results
    count.value = res.data.count
    isNextPage.value = !!res.data.next
  }
  catch (e: any) {
    state.error = e
  }
  finally {
    state.loading = false
  }

  return { data, count, isNextPage, ...toRefs(state) }
}

export const $getLocation = async (id: string | number, params: object = {}) => {
  const data = ref<any>([])

  const state = reactive({
    error: null,
    loading: false,
  })

  try {
    state.loading = true

    const res = await $http.get(`locations/${id}/`, { params })

    data.value = res.data
  }
  catch (e: any) {
    state.error = e
  }
  finally {
    state.loading = false
  }

  return { data, ...toRefs(state) }
}

export const $createLocation = async (payload: object) => {
  return await $http.post('locations/', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $updateLocation = async (id: number, payload: object) => {
  return await $http.patch(`locations/${id}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $deleteLocation = async (id: number) => {
  return await $http.patch(`locations/${id}/`, { is_removed: true })
}

export const $getGMapLocation = async (address: string) => {
  return await axios.get('https://maps.googleapis.com/maps/api/geocode/json', { params: { address, key: GMAP_KEY } })
}

// Project Location Modified File
export const $createProjectLocationModifiedFile = async (payload: object) => {
  // Override the timeout setting for this specific request
  const config = {
    timeout: 300000, // Timeout set to 300000ms (300 seconds)
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return await $http.post('project-location-modified-file/', payload, config)
}

export const $removeAllModifiedFilesByProjectAndLocation = async (payload: object) => {
  return await $http.post('remove-all-modified-files-by-project-and-location/', payload)
}

export const $getProjectLocationModifiedFiles = async (params: object) => {
  return await $http.get('project-location-modified-file/', { params })
}
