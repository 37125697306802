<script setup>
import { $getUserSigns } from '@/api/signs'
import { useDisplay } from 'vuetify'

const vuetifyDisplay = useDisplay

const items = ref([])
const itemsLoading = ref(false)
const currentPage = ref(1)
const nextPage = ref(null)
const previousPage = ref(null)

const perPage = computed(() => {
  return vuetifyDisplay.lgAndUp ? 40 : 20
})

const getItems = async () => {
  itemsLoading.value = true
  try {
    const res = await $getUserSigns({
      page: currentPage.value,
      per_page: perPage.value
    })

    items.value = res.data.results
    nextPage.value = res.data.next
    previousPage.value = res.data.previous
  } catch (e) {
    console.log(e)
  } finally {
    itemsLoading.value = false
  }
}

onMounted(() => getItems())
</script>

<template>
  <VCard title="Wayfinding Signs Assigned To You">
    <VCardText>
      <VOverlay
        v-model="itemsLoading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VTable
        :fixed-header="$vuetify.display.lgAndUp"
        :height="$vuetify.display.lgAndUp ? 1000 : ''"
      >
        <thead>
          <tr>
            <th>Sign Code</th>
            <th>Status</th>
            <th>Project</th>
            <th>Location</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template
            v-for="item in items"
            :key="item.id"
          >
            <tr>
              <td>{{ item.sign_code }}</td>
              <td>{{ item.sign_status_name }}</td>
              <td>{{ item.campaign_name }}</td>
              <td>{{ item.location_name }}</td>
              <td>
                <SignDetailsModal :sign-id="item.id" @sign-updated="getItems" />
              </td>
            </tr>
          </template>
        </tbody>
      </VTable>
      <div
        v-if="items.length"
        class="mt-2"
      >
        <VBtn
          size="small"
          class="mr-2"
          :disabled="!previousPage"
          @click="currentPage--; getItems()"
        >
          &lt;
        </VBtn>
        <VBtn
          size="small"
          :disabled="!nextPage"
          @click="currentPage++; getItems()"
        >
          >
        </VBtn>
      </div>
    </VCardText>
  </VCard>
</template>
