<script setup lang="ts">
import { Sign as SignInterface } from '@/models/sign'
import { useUserStore } from '@/store/user'

interface IProps {
  sign?: SignInterface,
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
})

interface CustomEvents {
  'update:sign': (newValue: SignInterface) => void;
}

const emit = defineEmits<CustomEvents>()

const userStore = useUserStore()

const computedSign = computed({
  // getter
  get: () => props.sign || {},  // Provide a fallback in case props.sign is undefined
  // setter
  set: (newSign: SignInterface) => {
    emit('update:sign', newSign) // Emit update to parent when the local sign is changed
  }
})
</script>

<template>
  <VCardText>
    <VRow>
      <VCol
        cols="12"
        sm="6"
      >
        <VTextarea
          v-model="computedSign.notes"
          label="Supplier Notes"
          :disabled="userStore.isCustomer"
        />
      </VCol>
      <VCol
        cols="12"
        sm="6"
      >
        <VTextarea
          v-model="computedSign.client_notes"
          label="Client Notes"
          :disabled="!userStore.isCustomer"
        />
      </VCol>
      <VCol
        cols="12"
        sm="6"
      >
        <VTextarea
          v-model="computedSign.fabric_notes"
          label="Notes for Fabricator"
          :disabled="userStore.isCustomer"
        />
      </VCol>
      <VCol
        cols="12"
        sm="6"
      >
        <VTextarea
          v-model="computedSign.instruction"
          label="Installation Instructions"
          :disabled="userStore.isCustomer"
        />
      </VCol>
    </VRow>
  </VCardText>
</template>

<style lang="scss" scoped>
</style>
